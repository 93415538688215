import React, { useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { ClipLoader } from "react-spinners";
import { useNavigate, Link } from "react-router-dom";
import { logEvent } from "../ga";
import { inject } from "@vercel/analytics";
import ChatContext from "../ChatContext";

// Initialize Vercel Analytics and GA only if not on localhost
if (window.location.hostname !== "localhost") {
  inject();
}

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { setTokenLimit, setTokensUsed } = useContext(ChatContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    // Log GA event only if not on localhost
    if (window.location.hostname !== "localhost") {
      logEvent("User", "Clicked Login Button");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        { username: email, password: password },
        { withCredentials: true }
      );
      Cookies.set("token", response.data.token);
      Cookies.set("username", response.data.username);

      // Set context for token limit and tokens used
      setTokenLimit(response.data.token_limit);
      setTokensUsed(response.data.tokens_used);

      onLogin();
      navigate("/app");
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Unable to login, please try after some time.");
      navigate("/confirm-email");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-purple-800 via-purple-600 to-purple-400 w-full">
      <div className="absolute top-4 left-4 md:top-8 md:left-8">
        <button
          onClick={() => navigate("/")}
          className="py-2 px-4 bg-white text-purple-600 font-semibold rounded-md shadow hover:bg-gray-100"
        >
          Back
        </button>
      </div>
      <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-md mx-4">
        <div className="flex items-center justify-center mb-6">
          <img
            src="/images/logo.jpg"
            alt="PrimeAIAccess Logo"
            className="h-20 w-20 rounded-full shadow-md"
          />
        </div>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              disabled={loading || googleLoading}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              disabled={loading || googleLoading}
            />
          </div>
          <button
            type="submit"
            className="w-full py-3 px-4 bg-purple-600 text-white font-semibold rounded-md shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={loading || googleLoading}
          >
            {loading ? (
              <ClipLoader color="#ffffff" loading={loading} size={20} />
            ) : (
              "Login"
            )}
          </button>
        </form>
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{" "}
            <Link to="/register" className="text-purple-600 hover:underline">
              Register
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Login);
