import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { FiMenu } from "react-icons/fi";
import { FaTrophy } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import ChatContext from "../ChatContext";
import UpgradeDialog from "./UpgradeDialog"; // Import the new component

const Sidebar = ({ onLogout }) => {
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false); // State for dialog

  const {
    chatSessions,
    setChatSessions,
    selectedSessionId,
    setSelectedSessionId,
  } = useContext(ChatContext);

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isDrawerOpen]);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/logout`,
        {},
        { withCredentials: true }
      );
      Cookies.remove("token");
      Cookies.remove("username");
      onLogout();
    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchChatSessions = async () => {
    try {
      const token = Cookies.get("token");
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/chat/sessions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      const sessions = data.sessions || [];

      // Fetch chat history for each session to get the initial messages
      const updatedSessions = await Promise.all(
        sessions.map(async (session) => {
          const historyResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/chat/history`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: { sessionId: session.sessionId },
            }
          );

          const messages = historyResponse.data.messages || [];
          const name =
            messages.length > 0 ? messages[0].content.slice(0, 30) : "New Chat";

          return { ...session, messages, name };
        })
      );

      setChatSessions(updatedSessions);

      if (updatedSessions.length > 0) {
        setSelectedSessionId(updatedSessions[0].sessionId);
      } else {
        createNewChatSession();
      }
    } catch (error) {
      console.error("Error fetching chat sessions:", error);
      if (error.response && error.response.status === 401) {
        onLogout(); // Handle unauthorized error
      }
    }
  };

  const createNewChatSession = () => {
    const emptySession = chatSessions.find(
      (session) => session.messages.length === 0
    );

    if (emptySession) {
      setSelectedSessionId(emptySession.sessionId);
    } else {
      const newSessionId = uuidv4();
      const newSession = {
        sessionId: newSessionId,
        messages: [],
        name: "New Chat",
      };
      setChatSessions((prevSessions) => [newSession, ...prevSessions]);
      setSelectedSessionId(newSessionId);
    }
  };

  useEffect(() => {
    fetchChatSessions();
  }, []);

  useEffect(() => {
    console.log("chat sessions: ", chatSessions);
  }, [chatSessions]);

  const username = Cookies.get("username");

  return (
    <>
      <div className="lg:hidden fixed top-4 left-4 z-50">
        <button
          className="text-white bg-gray-900 p-2 rounded"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          <FiMenu size={24} />
        </button>
      </div>

      <div
        className={`fixed top-0 left-0 w-full h-full bg-gray-900 text-gray-200 p-4 flex flex-col z-40 transform ${
          isDrawerOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out lg:relative lg:translate-x-0 lg:w-1/5 lg:max-w-xs lg:h-auto lg:bg-gray-900 lg:p-4 lg:overflow-y-auto`}
      >
        <div className="text-2xl font-bold mb-4">
          <img
            src="/images/logo.jpg"
            alt="Chats Logo"
            className="h-20 w-20 mx-auto rounded-full"
          />
        </div>
        <button
          className="w-full bg-black text-white p-2 rounded mb-4"
          onClick={createNewChatSession}
        >
          New Chat
        </button>
        <div className="flex-1 overflow-y-auto custom-scroll">
          {chatSessions.length > 0 ? (
            chatSessions.map((session) => (
              <button
                key={session.sessionId}
                className="w-full mb-4 p-2 bg-gray-800 rounded shadow text-gray-400 text-left overflow-hidden overflow-ellipsis"
                onClick={() => setSelectedSessionId(session.sessionId)}
              >
                {session.name.charAt(0).toUpperCase() + session.name.slice(1)}
                {session.name.length > 20 ? "..." : ""}
              </button>
            ))
          ) : (
            <div className="text-center text-gray-500">No chat created yet</div>
          )}
        </div>
        <div className="mt-4">
          <div className="text-gray-400">{username}</div>
        </div>
        <button
          onClick={handleLogout}
          className="mt-4 w-full bg-gray-700 text-gray-200 p-2 rounded shadow-lg hover:bg-gray-600 transition duration-200 ease-in-out transform hover:scale-105"
          disabled={loading}
        >
          {loading ? (
            <ClipLoader color="#ffffff" loading={loading} size={20} />
          ) : (
            "Logout"
          )}
        </button>
        <button
          className="mt-4 w-full bg-yellow-500 text-gray-900 p-2 rounded shadow-lg hover:bg-yellow-400 transition duration-200 ease-in-out transform hover:scale-105 flex items-center justify-center"
          onClick={() => setIsUpgradeDialogOpen(true)} // Open dialog
        >
          <FaTrophy className="mr-2" size={20} />
          Token Topup
        </button>
      </div>

      {isDrawerOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30 lg:hidden"
          onClick={() => setIsDrawerOpen(false)}
        ></div>
      )}

      {isUpgradeDialogOpen && (
        <UpgradeDialog onClose={() => setIsUpgradeDialogOpen(false)} />
      )}

      <style jsx>{`
        .custom-scroll::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scroll::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
        .custom-scroll {
          scrollbar-width: thin;
          scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
        }
        button.text-left {
          text-align: left;
        }
      `}</style>
    </>
  );
};

export default React.memo(Sidebar);
