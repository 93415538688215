import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";

const Register = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const passwordRegex = /^(?=.*[!@#$%^&*(),.?":{}|<>]).{6,}$/;
    if (!passwordRegex.test(password)) {
      setLoading(false);
      setError(
        "Password must be at least 6 characters long and contain at least one special character."
      );
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        { username: email, password: password },
        { withCredentials: true }
      );
      Cookies.set("token", response.data.token, {
        sameSite: "Strict",
        secure: true,
      });
      Cookies.set("username", response.data.username, {
        sameSite: "Strict",
        secure: true,
      });
      setError("");
      onLogin(); // Call the onLogin callback
      navigate("/confirm-email");
    } catch (error) {
      console.error("Error registering:", error);
      setError("Something went wrong, please try after some time.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-800 via-purple-600 to-purple-400 w-full p-4">
      <div className="w-full max-w-md bg-white shadow-lg p-8 md:p-16 font-sans rounded-lg">
        <div className="flex justify-center mb-8">
          <img
            src="images/logo.jpg"
            alt="Your Logo"
            className="w-32 rounded-full"
          />
        </div>
        <h2 className="text-3xl font-bold text-center mb-6 text-purple-700">
          Join Now!
        </h2>
        {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
        <form onSubmit={handleRegister} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              disabled={loading}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
              disabled={loading}
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full py-3 px-4 bg-purple-600 text-white font-semibold rounded-md shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? (
                <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full"></div>
              ) : (
                "Sign up"
              )}
            </button>
          </div>
          <p className="mt-2 text-sm text-center">
            Already have an account?{" "}
            <Link to="/app/login" className="text-purple-600 hover:underline">
              Login
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default React.memo(Register);
