import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const MessageInput = ({
  inputMessage,
  setInputMessage,
  handleKeyDown,
  sendMessage,
  loading,
  textareaRef,
}) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setShowMessage(true);
      }, 2000);
    } else {
      setShowMessage(false);
    }
    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <div className="w-full p-2 flex flex-col items-center">
      {showMessage && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mb-2 text-gray-500 gradient-text"
          style={{
            background: "linear-gradient(90deg, #ff8a00, #e52e71)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            color: "transparent",
            animation: "gradient-animation 0.2s infinite",
            fontSize: "0.7rem", // Add this line for consistent size
            fontWeight: "bold",
            textDecoration: "uppercase",
          }}
        >
          PLEASE BE PATIENT, AI IS GENERATING A RESPONSE...
          {/* Please be patient, AI is generating a response... */}
        </motion.div>
      )}
      <div className="w-full flex items-center">
        <textarea
          ref={textareaRef}
          className="w-full p-2 border rounded resize-none"
          rows={1}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
          disabled={loading}
        />
        <button
          onClick={sendMessage}
          className="ml-2 bg-blue-500 text-white p-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? (
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{
                repeat: Infinity,
                ease: "linear",
                duration: 1,
              }}
              className="loader p-2.5 px-2.5"
            ></motion.div>
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} />
          )}
        </button>
      </div>
      <style jsx>{`
        @keyframes gradient-animation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        .gradient-text {
          background-size: 200% 200%;
        }
      `}</style>
    </div>
  );
};

export default MessageInput;
