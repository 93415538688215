import React from "react";
import { FaRupeeSign, FaTimes } from "react-icons/fa";
import PaymentButton from "./PaymentButton";

const UpgradeDialog = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4 sm:p-8 overflow-y-auto">
      <div className="relative bg-white p-6 sm:p-8 rounded-lg shadow-lg max-w-4xl w-full text-black overflow-y-auto max-h-full">
        <FaTimes
          className="absolute top-4 right-4 text-gray-500 cursor-pointer"
          size={24}
          onClick={onClose}
        />
        <h2 className="text-2xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center">
          Pricing Plans
        </h2>
        <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-6">
          <div className="bg-gray-100 p-6 rounded-lg flex-1 flex flex-col items-center justify-between">
            <div className="flex flex-col items-center">
              <h3 className="text-xl sm:text-3xl font-semibold mb-4">
                Get Started
              </h3>
              <div className="text-2xl sm:text-3xl font-bold flex items-center justify-center mb-4">
                <FaRupeeSign className="mr-0" />
                {process.env.REACT_APP_TIER_ONE}
              </div>
              <p className="text-gray-600 mb-4 text-center">
                You get 75,000 Tokens <br></br>(Input + Output) <br></br>
                <span className="font-bold bg-green-200">
                  Equivalent to about 75,000 words or approximately 150
                  articles.
                </span>
              </p>
              <p className="text-gray-600 text-center">
                Ideal for Marketers, Sales, and SEO Professionals
              </p>
            </div>
            <PaymentButton
              amount={process.env.REACT_APP_TIER_ONE}
              planType="Lifetime"
              tokenLimit={75000}
              onClose={onClose}
            />
          </div>
          <div className="bg-gray-100 p-6 rounded-lg flex-1 flex flex-col items-center justify-between">
            <div className="flex flex-col items-center">
              <h3 className="text-xl sm:text-3xl font-semibold mb-4">
                Get Going
              </h3>
              <div className="text-2xl sm:text-3xl font-bold flex items-center justify-center mb-4">
                <FaRupeeSign className="mr-0" />
                {process.env.REACT_APP_TIER_TWO}
              </div>
              <p className="text-gray-600 mb-4 text-center">
                You get 2,00,000 Tokens (Input + Output)<br></br>
                <span className="font-bold bg-green-200">
                  Equivalent to about 2,00,000 words or approximately 400
                  articles.
                </span>
              </p>
              <p className="text-gray-600 text-center">
                Ideal for Project Managers, Business Analysts, Programmers, and
                Entrepreneurs
              </p>
            </div>
            <PaymentButton
              amount={process.env.REACT_APP_TIER_TWO}
              planType="Lifetime"
              tokenLimit={200000}
              onClose={onClose}
            />
          </div>
          <div className="bg-yellow-100 p-6 rounded-lg flex-1 flex flex-col items-center justify-between border-4 border-yellow-500">
            <div className="flex flex-col items-center">
              <h3 className="text-xl sm:text-3xl font-semibold mb-4">
                Get Ahead
              </h3>
              <div className="text-2xl sm:text-3xl font-bold flex items-center justify-center mb-4">
                <FaRupeeSign className="mr-0" />
                {process.env.REACT_APP_TIER_THREE}
              </div>
              <p className="text-gray-600 mb-4 text-center">
                You get 3,00,000 Tokens (Input + Output)<br></br>
                <span className="font-bold bg-green-200">
                  Equivalent to about 3,00,000 words or approximately 600
                  articles.
                </span>
              </p>
              <p className="text-gray-600 text-center">
                Ideal for Content Writers, Content Creators, and Publishing
                Houses
              </p>
              <p className="text-sm text-red-600 font-bold text-center mt-2">
                Limited Time Offer
              </p>
            </div>
            <PaymentButton
              amount={process.env.REACT_APP_TIER_THREE}
              planType="Lifetime"
              tokenLimit={300000}
              onClose={onClose}
            />
          </div>
        </div>
        <div className="text-center mt-4">
          <p style={{ fontSize: "12px", color: "#a29f9f" }}>
            None of the plans allow image generation, only text generation. By
            clicking "Buy Now", you agree to our{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              className="text-blue-500 underline"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="/refund-policy"
              target="_blank"
              className="text-blue-500 underline"
            >
              Refund Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpgradeDialog;
