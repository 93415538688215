import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const Footer = () => (
  <footer className="w-full py-8 bg-gray-900 text-gray-400">
    <Box className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
      <Box className="text-center md:text-left mb-4 md:mb-0">
        <Typography variant="h6" className="text-gray-100 mb-2">
          PrimeAIAccess
        </Typography>
        <Typography variant="body2">
          &copy; 2024 PrimeAIAccess. All rights reserved.
        </Typography>
      </Box>
      <Box className="flex flex-col md:flex-row md:items-center md:space-x-4 text-center md:text-left">
        <Link
          to="/about-us"
          className="footer-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          About Us
        </Link>
        <Link
          to="/terms-conditions"
          className="footer-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </Link>
        <Link
          to="/refund-policy"
          className="footer-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Refund Policy
        </Link>
        <Link
          to="/contact-us"
          className="footer-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </Link>
        <Link
          to="/privacy-policy"
          className="footer-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </Box>
    </Box>
  </footer>
);

export default Footer;
