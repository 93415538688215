import React from "react";

const TermsConditions = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">Terms & Conditions</h1>
      <p>
        Welcome to Chandra Web Solutions. These Terms and Conditions govern your
        use of our services, including the ChatGPT subscription services. By
        using our services, you agree to these terms. Please read them
        carefully.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">1. Subscription Services</h2>
      <p>
        By subscribing to our ChatGPT services, you agree to pay the
        subscription fees as specified at the time of purchase. Subscriptions
        are billed in advance on a monthly or yearly basis, as per your
        selection.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">2. Payment and Billing</h2>
      <p>
        Payments are processed through secure third-party payment gateways. You
        agree to provide accurate and complete payment information. If a payment
        is not successfully processed, we reserve the right to suspend or
        terminate your subscription.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">3. Refund Policy</h2>
      <p>
        Our refund policy is outlined in the{" "}
        <a href="/refund-policy" className="text-blue-500">
          Refund Policy
        </a>{" "}
        page. Please review it for details on how refunds are handled, including
        the provision that refunds may take up to 30 days to process.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">4. Use of Services</h2>
      <p>
        You agree to use our services only for lawful purposes and in accordance
        with these Terms. You are responsible for all activities that occur
        under your account.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">5. Account Security</h2>
      <p>
        You are responsible for maintaining the confidentiality of your account
        information and password. You agree to notify us immediately of any
        unauthorized use of your account.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">6. Intellectual Property</h2>
      <p>
        All content, trademarks, and data on this site, including but not
        limited to software, databases, text, graphics, icons, hyperlinks,
        private information, designs, and agreements, are the property of or
        licensed to Chandra Web Solutions and as such are protected from
        infringement by local and international legislation and treaties.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">7. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your subscription and
        access to our services at our sole discretion, without prior notice, for
        conduct that we believe violates these Terms or is harmful to other
        users of our services, us, or third parties, or for any other reason.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        8. Limitation of Liability
      </h2>
      <p>
        To the maximum extent permitted by law, Chandra Web Solutions shall not
        be liable for any indirect, incidental, special, consequential or
        punitive damages, or any loss of profits or revenues, whether incurred
        directly or indirectly, or any loss of data, use, goodwill, or other
        intangible losses, resulting from (a) your use or inability to use the
        services; (b) any unauthorized access to or use of our servers and/or
        any personal information stored therein; (c) any interruption or
        cessation of transmission to or from the services; (d) any bugs,
        viruses, trojan horses, or the like that may be transmitted to or
        through the services by any third party; or (e) any errors or omissions
        in any content or for any loss or damage of any kind incurred as a
        result of your use of any content posted, emailed, transmitted, or
        otherwise made available via the services.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">9. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of India, without regard to its conflict of law provisions.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">10. Changes to Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. Any changes will
        be posted on this page, and we will notify you of significant changes.
        Your continued use of the services after any modifications indicates
        your acceptance of the new Terms.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">11. Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms, please contact
        us at{" "}
        <a href="mailto:support@primeaiaccess.com" className="text-blue-500">
          support@primeaiaccess.com
        </a>
        .
      </p>

      <p>Last updated: [Date]</p>
    </div>
  );
};

export default TermsConditions;
