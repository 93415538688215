import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { inject } from "@vercel/analytics";
import { marked } from "marked";
import MessageBubble from "./MessageBubble";
import MessageInput from "./MessageInput";
import { v4 as uuidv4 } from "uuid";
import ChatContext from "../ChatContext";
import TokenUsage from "./TokenUsage";

inject();

const ChatList = ({ onLogout }) => {
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const [copiedMessageId, setCopiedMessageId] = useState(null);

  const {
    chatSessions,
    setChatSessions,
    selectedSessionId,
    setSelectedSessionId,
    updateSessionName,
    tokenLimit,
    tokensUsed,
    setTokenLimit,
    setTokensUsed,
  } = useContext(ChatContext);

  const messages =
    chatSessions.find((session) => session.sessionId === selectedSessionId)
      ?.messages || [];

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) onLogout();
  }, [onLogout]);

  useEffect(() => {
    if (selectedSessionId) {
      fetchChatHistory(selectedSessionId);
    } else if (chatSessions.length === 0) {
      createNewChatSession();
    }
  }, [selectedSessionId, chatSessions.length]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        80
      )}px`;
    }
  }, [inputMessage]);

  const refreshToken = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/refresh-token`,
        {},
        { withCredentials: true }
      );
      Cookies.set("token", data.token);
      return data.token;
    } catch (error) {
      console.error("Error refreshing token:", error);
      onLogout();
    }
  };

  const axiosInstance = axios.create();
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = Cookies.get("token");
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        !error.config._retry
      ) {
        error.config._retry = true;
        const newToken = await refreshToken();
        axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        return axiosInstance(error.config);
      }
      return Promise.reject(error);
    }
  );

  const fetchChatHistory = async (sessionId) => {
    console.log(`Fetching chat history for sessionId: ${sessionId}`);
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/chat/history`,
        { params: { sessionId } }
      );
      console.log("Fetched chat history:", data.messages);
      setChatSessions((prevSessions) =>
        prevSessions.map((session) =>
          session.sessionId === sessionId
            ? {
                ...session,
                messages: data.messages || [],
                name:
                  data.messages && data.messages.length > 0
                    ? data.messages[0].content.slice(0, 30)
                    : session.name || "New Chat",
              }
            : session
        )
      );
      console.log("Messages state after fetching history:", data.messages);
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const createNewChatSession = () => {
    const newSessionId = uuidv4();
    setChatSessions((prevSessions) => [
      { sessionId: newSessionId, messages: [], name: "New Chat" },
      ...prevSessions,
    ]);
    setSelectedSessionId(newSessionId);
  };

  const sendMessage = async () => {
    console.log("sendMessage called");
    console.log("Current selectedSessionId:", selectedSessionId);
    if (!selectedSessionId) {
      console.error("sessionId is not selected");
      return;
    }

    const newMessage = {
      id: uuidv4(),
      role: "user",
      content: inputMessage,
      timestamp: new Date().toLocaleTimeString(),
    };

    console.log("Adding user message:", newMessage);
    setChatSessions((prevSessions) =>
      prevSessions.map((session) =>
        session.sessionId === selectedSessionId
          ? { ...session, messages: [...session.messages, newMessage] }
          : session
      )
    );
    if (messages.length === 0) {
      updateSessionName(selectedSessionId, inputMessage.slice(0, 30));
    }

    setInputMessage("");
    setLoading(true);
    if (textareaRef.current) textareaRef.current.focus();

    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/chat`,
        { message: inputMessage, sessionId: selectedSessionId }
      );
      const responseMessage = {
        id: uuidv4(),
        role: "assistant",
        content: data.response,
        timestamp: new Date().toLocaleTimeString(),
      };

      console.log("Adding AI response message:", responseMessage);
      setChatSessions((prevSessions) =>
        prevSessions.map((session) =>
          session.sessionId === selectedSessionId
            ? { ...session, messages: [...session.messages, responseMessage] }
            : session
        )
      );

      setTokenLimit(data.token_limit);
      setTokensUsed(data.tokens_used);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
    }
  };

  const createMarkup = (text) => ({
    __html: text ? marked(text.replace(/\n\n/g, "\n \n")) : "",
  });

  const copyToClipboard = (htmlContent) => {
    const el = document.createElement("div");
    el.innerHTML = createMarkup(htmlContent).__html;
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const range = document.createRange();
    range.selectNodeContents(el);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();
    document.body.removeChild(el);
  };

  const handleCopyClick = (htmlContent, id) => {
    copyToClipboard(htmlContent);
    setCopiedMessageId(id);
    setTimeout(() => setCopiedMessageId(null), 2000);
  };

  return (
    <div className="w-full p-4 flex justify-center bg-white text-gray-900 h-full overflow-x-hidden">
      <div className="w-full max-w-screen-lg flex flex-col custom-scroll">
        <div
          className="flex-1 overflow-y-auto custom-scroll"
          ref={chatContainerRef}
        >
          {messages.length === 0 && (
            <div className="text-center text-gray-500">Start a new chat</div>
          )}
          {messages.map((message) => (
            <MessageBubble
              key={message.id}
              message={message}
              copiedMessageId={copiedMessageId}
              handleCopyClick={handleCopyClick}
              createMarkup={createMarkup}
            />
          ))}
        </div>
        <MessageInput
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              sendMessage();
            }
          }}
          sendMessage={sendMessage}
          loading={loading}
          textareaRef={textareaRef}
        />
      </div>
      <div className="">
        <TokenUsage />
        <a
          href="https://insigh.to/b/chatbot-features-you-wanted"
          target="_blank"
          rel="noopener noreferrer"
          className="hidden md:block fixed top-40 right-6 bg-blue-600 text-white px-3 py-2 rounded-full shadow-md hover:bg-blue-700 transition"
          style={{ zIndex: 1000 }}
        >
          Feedback
        </a>
      </div>
      <style jsx>{`
        .custom-scroll::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scroll::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
        .custom-scroll {
          scrollbar-width: thin;
          scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
        }
      `}</style>
    </div>
  );
};

export default React.memo(ChatList);
