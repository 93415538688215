import React from "react";

const MessageBubble = ({
  message,
  copiedMessageId,
  handleCopyClick,
  createMarkup,
}) => {
  const { role, content, id } = message;
  console.log("whote message: ", message);
  return (
    <div
      className={`mb-6 p-4 rounded-lg shadow ${
        role === "user" ? "self-end bg-white" : "self-start bg-gray-100"
      }`}
    >
      <div className="text-xs text-gray-300 mb-1">
        {role === "user" ? "User" : "AI"}
      </div>
      <div dangerouslySetInnerHTML={createMarkup(content)} />
      {role !== "user" && (
        <button
          onClick={() => handleCopyClick(content, id)}
          className="mt-2 text-blue-500 text-xs"
        >
          {copiedMessageId === id ? "Copied!" : "Copy"}
        </button>
      )}
    </div>
  );
};

export default MessageBubble;
