import React from "react";

const RefundPolicy = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">Refund Policy</h1>
      <p>
        At Chandra Software Services, we strive to ensure customer satisfaction
        with our ChatGPT subscription services. This Refund Policy outlines the
        terms and conditions under which refunds may be issued.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        1. Eligibility for Refunds
      </h2>
      <p>Refunds will be considered under the following circumstances:</p>
      <ul className="list-disc ml-6">
        <li>
          <strong>Technical Issues:</strong> If you encounter technical issues
          that prevent you from accessing the ChatGPT services and our support
          team is unable to resolve the issue within a reasonable time frame.
        </li>
        <li>
          <strong>Service Discrepancies:</strong> If the service provided does
          not match the description or promises made at the time of
          subscription.
        </li>
        <li>
          <strong>Unused Tokens:</strong> If no tokens were used after the
          purchase.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        2. Refund Request Process
      </h2>
      <p>To request a refund, please follow these steps:</p>
      <ul className="list-disc ml-6">
        <li>
          Contact our support team at{" "}
          <a href="mailto:support@primeaiaccess.com" className="text-blue-500">
            support@primeaiaccess.com
          </a>{" "}
          with your subscription details and the reason for the refund request.
        </li>
        <li>
          Our team will review your request and may ask for additional
          information to process the refund.
        </li>
        <li>
          We aim to respond to all refund requests within 5 business days.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        3. Refund Processing Time
      </h2>
      <p>If your refund request is approved, please note the following:</p>
      <ul className="list-disc ml-6">
        <li>
          Refunds will be processed to the original payment method used during
          the subscription.
        </li>
        <li>
          <strong>Refunds may take up to 30 days to process</strong> from the
          date of approval, depending on your bank or payment provider.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">4. Non-Refundable Items</h2>
      <p>The following items are non-refundable:</p>
      <ul className="list-disc ml-6">
        <li>One-time setup fees, if applicable.</li>
        <li>Any promotional or discounted subscriptions.</li>
        <li>
          Token-based plans are limited by token usage. Each plan has a token
          limit, and once that limit is reached, access to the product will be
          denied. No refunds will be issued for the inability to access the
          product due to the token limit.
        </li>
        <li>
          Fair use policy: Users who abuse the features beyond the scope of fair
          use will be banned, and no refund will be provided.
        </li>
        <li>No partial refunds will be provided for token-based plans.</li>
        <li>If all tokens are used, you will not be eligible for a refund.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        5. Changes to This Refund Policy
      </h2>
      <p>
        We reserve the right to update this Refund Policy at any time. Changes
        will be posted on this page, and we will notify you of significant
        updates.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        6. How to Ask for a Refund
      </h2>
      <p className="font-bold">
        To request a refund, please contact our support team at{" "}
        <a href="mailto:support@primeaiaccess.com" className="text-blue-500">
          support@primeaiaccess.com
        </a>
        .
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">7. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Refund Policy, please
        contact us at{" "}
        <a href="mailto:support@primeaiaccess.com" className="text-blue-500">
          support@primeaiaccess.com
        </a>
        .
      </p>

      <p>Last updated: 29-June-2024</p>
    </div>
  );
};

export default RefundPolicy;
