import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p>
        Welcome to Chandra Web Solutions, your trusted ChatGPT subscription
        reseller. We are committed to protecting your privacy and ensuring the
        security of your personal information. This Privacy Policy outlines how
        we collect, use, and safeguard your data in compliance with applicable
        laws in India.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        1. Information We Collect
      </h2>
      <p>We collect the following types of information:</p>
      <ul className="list-disc ml-6">
        <li>
          <strong>Personal Information:</strong> Name, email address, contact
          number, and billing information when you subscribe to our services.
        </li>
        <li>
          <strong>Payment Information:</strong> Credit/debit card details or
          other payment methods to process your subscription payments.
        </li>
        <li>
          <strong>Usage Data:</strong> Information on how you use our services,
          including interactions with ChatGPT, to improve user experience.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        2. How We Use Your Information
      </h2>
      <p>We use the collected information for the following purposes:</p>
      <ul className="list-disc ml-6">
        <li>
          <strong>To Provide Services:</strong> To process your subscription and
          provide access to ChatGPT services.
        </li>
        <li>
          <strong>To Process Payments:</strong> To securely process your
          subscription payments through trusted third-party payment gateways.
        </li>
        <li>
          <strong>To Improve Services:</strong> To analyze usage data and
          enhance our service offerings.
        </li>
        <li>
          <strong>To Communicate:</strong> To send you updates, promotional
          offers, and respond to your inquiries.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">3. Data Security</h2>
      <p>
        We implement robust security measures to protect your personal
        information from unauthorized access, alteration, or disclosure. All
        payment transactions are encrypted and processed through secure payment
        gateways.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        4. Data Sharing and Disclosure
      </h2>
      <p>
        We do not sell, trade, or rent your personal information to third
        parties. However, we may share your data with:
      </p>
      <ul className="list-disc ml-6">
        <li>
          <strong>Service Providers:</strong> Trusted third-party service
          providers who assist us in delivering our services.
        </li>
        <li>
          <strong>Legal Compliance:</strong> Authorities when required by law or
          to protect our rights and ensure user safety.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">5. Chat Data Retention</h2>
      <p>
        All chat data is automatically deleted from our servers after 4 days. No
        backups or archives of chat data are maintained, ensuring your privacy
        is preserved.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">6. Your Rights</h2>
      <p>As a user, you have the right to:</p>
      <ul className="list-disc ml-6">
        <li>Access and update your personal information.</li>
        <li>
          Request deletion of your data, subject to legal and contractual
          obligations.
        </li>
        <li>Opt-out of receiving promotional communications.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        7. Changes to This Privacy Policy
      </h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page, and we will notify you of significant updates.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at{" "}
        <a href="mailto:support@primeaiaccess.com" className="text-blue-500">
          support@primeaiaccess.com
        </a>
        .
      </p>

      <p>Last updated: 29-June-2024</p>
    </div>
  );
};

export default PrivacyPolicy;
