import React, { createContext, useState } from "react";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatSessions, setChatSessions] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState([]);
  const [tokenLimit, setTokenLimit] = useState(0);
  const [tokensUsed, setTokensUsed] = useState(0);

  const updateSessionName = (sessionId, newName) => {
    setChatSessions((prevSessions) =>
      prevSessions.map((session) =>
        session.sessionId === sessionId
          ? { ...session, name: newName }
          : session
      )
    );
  };

  return (
    <ChatContext.Provider
      value={{
        chatSessions,
        setChatSessions,
        selectedSessionId,
        setSelectedSessionId,
        updateSessionName,
        tokenLimit,
        setTokenLimit,
        tokensUsed,
        setTokensUsed,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContext;
