import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Sidebar from "./components/Sidebar";
import ChatList from "./components/ChatList";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import CheckEmail from "./components/CheckEmail";
import VerifyEmail from "./components/VerifyEmail";
import TermsConditions from "./components/TermsConditions";
import RefundPolicy from "./components/RefundPolicy";
import ContactUs from "./components/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import "./index.css";
// import { initGA, logPageView } from "./ga"; // Import GA functions
// import { inject } from "@vercel/analytics";
import { ChatProvider } from "./ChatContext"; // Import ChatProvider

// Initialize Vercel Analytics only if not on localhost
// if (window.location.hostname !== "localhost") {
//   inject();
// }

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      setIsAuthenticated(true);
    }
    // initGA(); // Initialize Google Analytics conditionally
  }, []);

  // useEffect(() => {
  //   logPageView(); // Log initial page view
  // }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/logout`,
        {},
        { withCredentials: true }
      );
      Cookies.remove("token");
      Cookies.remove("username");
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <ChatProvider>
      <Router>
        <RouteChangeTracker /> {/* Add RouteChangeTracker component */}
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute isAuthenticated={isAuthenticated}>
                <Home />
              </PublicRoute>
            }
          />
          <Route
            path="/app/login"
            element={
              <PublicRoute isAuthenticated={isAuthenticated}>
                <Login onLogin={handleLogin} />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute isAuthenticated={isAuthenticated}>
                <Register onLogin={handleLogin} />
              </PublicRoute>
            }
          />
          <Route
            path="/app"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <ProtectedApp onLogout={handleLogout} />
              </ProtectedRoute>
            }
          />
          <Route path="/confirm-email" element={<CheckEmail />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ChatProvider>
  );
};

// Component to log page views on route change
const RouteChangeTracker = () => {
  const location = useLocation();

  // useEffect(() => {
  //   logPageView(); // Log page view on route change
  // }, [location]);

  return null;
};

const ProtectedRoute = ({ isAuthenticated, children }) => {
  const token = Cookies.get("token");
  return token ? children : <Navigate to="/app/login" />;
};

const PublicRoute = ({ isAuthenticated, children }) => {
  const token = Cookies.get("token");
  return token ? <Navigate to="/app" /> : children;
};

const ProtectedApp = ({ onLogout }) => (
  <div className="flex h-screen w-full">
    <Sidebar onLogout={onLogout} />
    <div className="flex-1">
      <ChatList onLogout={onLogout} />
    </div>
  </div>
);

export default App;
