import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import { useNavigate, Link } from "react-router-dom";
import { FaUnlock, FaHistory, FaServer, FaUsers } from "react-icons/fa";
// import { logPageView, logEvent } from "../ga";
// import { inject } from "@vercel/analytics";

// if (window.location.hostname !== "localhost") {
//   inject();
// }

const CheckEmail = ({ onLogin }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-800 via-purple-600 to-purple-400 w-full">
      <div className="pt-6 bg-white shadow-lg w-full max-w-4xl flex flex-col md:flex-row font-sans rounded-lg items-center justify-center">
        <p>
          Please confirm your email by clicking the link sent to your registerd
          email address.
        </p>
      </div>
    </div>
  );
};

export default React.memo(CheckEmail);
