import React from "react";

const ContactUs = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <p>
        We are Chadra Software Services, you can reach us at{" "}
        <a href="mailto:support@primeaiaccess.com" className="text-blue-500">
          support@primeaiaccess.com
        </a>
      </p>
      {/* <p>Address: Nandanpuri Colony, Khajepura, Patna, Bihar, 800014</p> */}
    </div>
  );
};

export default ContactUs;
