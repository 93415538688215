import React from "react";
import {
  FaCoins,
  FaCloud,
  FaTachometerAlt,
  FaUsers,
  FaTrashAlt,
  FaShieldAlt,
} from "react-icons/fa";
import { Box, Typography } from "@mui/material";

const Features = () => {
  const features = [
    {
      icon: <FaCoins />,
      title: "You Buy Tokens",
      description:
        "Purchase tokens as per your need and avoid overpaying for fixed expensive subscriptions.",
    },
    {
      icon: <FaShieldAlt />,
      title: "Efficient Algorithms",
      description:
        "Advanced algorithms ensure minimal tokens usage for optimal responses.",
    },
    {
      icon: <FaCloud />,
      title: "Cloud Login",
      description:
        "No need to install software locally. Access our services from web, mobile, and anywhere else.",
    },
    {
      icon: <FaTachometerAlt />,
      title: "Token Usage Counter",
      description:
        "Monitor your token usage to keep track and optimize your consumption efficiently.",
    },
    {
      icon: <FaUsers />,
      title: "Account Sharing",
      description:
        "Enjoy the flexibility of sharing your account with as many people as you like.",
    },
    {
      icon: <FaTrashAlt />,
      title: "Privacy Protection",
      description:
        "We automatically delete chats after 4 days to ensure your privacy is maintained.",
    },
  ];

  return (
    <section className="w-full py-20 bg-gradient-to-br from-gray-100 via-gray-200 to-gray-300 text-gray-800">
      <div className="max-w-6xl mx-auto px-8 lg:px-16">
        <Typography
          variant="h2"
          className="text-4xl font-black mb-16 text-gray-900 text-center pb-16"
          style={{ fontFamily: "Open Sans, sans-serif" }}
        >
          How our system works
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12">
          {features.map((feature) => (
            <Box
              key={feature.title}
              className="feature-card p-6 lg:p-8 bg-white rounded-lg shadow-lg space-y-4 transition-transform transform hover:-translate-y-2 text-left"
              style={{ fontFamily: "Open Sans, sans-serif" }}
            >
              <Box className="icon-container bg-gray-100 p-2 lg:p-4 rounded-full mx-auto">
                <div className="text-3xl text-gray-800">{feature.icon}</div>
              </Box>
              <Typography
                variant="h5"
                className="text-xl font-bold text-gray-900"
              >
                {feature.title}
              </Typography>
              <Typography variant="body1" className="text-gray-600">
                {feature.description}
              </Typography>
            </Box>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
