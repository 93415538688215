import ReactGA from "react-ga";

let isGAInitialized = false;

export const initGA = () => {
  if (window.location.hostname !== "localhost" && !isGAInitialized) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    isGAInitialized = true;
  }
};

export const logPageView = () => {
  if (isGAInitialized) {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }
};

export const logEvent = (category = "", action = "") => {
  if (isGAInitialized && category && action) {
    ReactGA.event({ category, action });
  }
};
