import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const VerifyEmail = ({ onLogin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(
    "Please confirm your email by clicking the link sent to your registered email address."
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/auth/verify-email?token=${token}`
        )
        .then((response) => {
          setMessage(response.data.message);
          setTimeout(() => {
            navigate("/login");
          }, 2000); // Redirect after 2 seconds
        })
        .catch((error) => {
          setMessage(error.response?.data?.message || "Error verifying email.");
        });
    }
  }, [location, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-800 via-purple-600 to-purple-400 w-full">
      <div className="bg-white shadow-lg w-full max-w-4xl flex flex-col md:flex-row font-sans p-8">
        <h2>Verified! Redirecting to login....</h2>
      </div>
    </div>
  );
};

export default React.memo(VerifyEmail);
