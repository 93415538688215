import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUnlock, FaTimes } from "react-icons/fa";
// import { logPageView, logEvent } from "../ga";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Box,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Features from "./Features";
import Footer from "./Footer";
import "./Home.css";

const Home = () => {
  useEffect(() => {
    // logPageView();
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    return () => document.head.removeChild(link);
  }, []);

  const handleUnlockAccessClick = () => {};
  const handleLoginClick = () => {};

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;
    setDrawerOpen(open);
  };

  const handleScroll = () => setScrolled(window.scrollY > 0);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  const drawerList = (
    <Box
      sx={{
        width: 250,
        background: "rgba(41, 13, 63, 0.7)",
        color: "white",
        height: "100%",
        padding: "1rem",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <IconButton onClick={toggleDrawer(false)} sx={{ color: "white" }}>
          <FaTimes />
        </IconButton>
      </Box>
      <List>
        {["Login", "Unlock Access"].map((text, index) => (
          <ListItem
            button
            component={Link}
            to={index === 0 ? "/app/login" : "/register"}
            onClick={index === 0 ? handleLoginClick : handleUnlockAccessClick}
            key={text}
            className="drawer-link"
            sx={{
              backgroundColor: index === 1 ? "#FBBF24" : "#1e293b",
              color: index === 1 ? "black" : "white",
              fontWeight: index === 1 ? "bold" : "normal",
              borderRadius: "8px",
              marginBottom: "0.5rem",
              "&:hover": {
                backgroundColor: index === 1 ? "#FBBF24" : "#334155",
              },
              textAlign: "center",
            }}
          >
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div
      className="min-h-screen bg-gradient-to-r from-purple-800 via-purple-600 to-purple-400 text-gray-200 font-sans"
      style={{ fontFamily: "Open Sans, sans-serif" }}
    >
      <AppBar
        position="fixed"
        className={`app-bar ${scrolled ? "scrolled" : ""}`}
        sx={{
          backgroundColor: scrolled
            ? "rgba(0, 0, 0, 0.7)"
            : "rgba(255, 255, 255, 0.1)",
          backdropFilter: "blur(10px)",
          boxShadow: "none",
          transition: "background-color 0.3s ease",
          padding: "1rem 2rem",
        }}
      >
        <Toolbar className="flex justify-between px-8 flex-wrap">
          <Box className="flex items-center space-x-4 fade-in-up">
            <img
              src="/images/logo.jpg"
              alt="PrimeAIAccess Logo"
              className="h-16 w-16 rounded-full"
            />
          </Box>
          <Box className="md:hidden fade-in-up">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {drawerList}
            </Drawer>
          </Box>
          <Box className="space-x-8 flex items-center text-lg font-light hidden md:flex fade-in-up">
            <Link
              to="/app/login"
              className="header-link"
              onClick={handleLoginClick}
            >
              Login
            </Link>
            <Link
              to="/register"
              className="unlock-access"
              onClick={handleUnlockAccessClick}
            >
              Unlock Access
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <main className="flex flex-1 flex-col items-center justify-center px-8 lg:px-16 pt-24 lg:pt-32 pb-24 lg:pb-40 text-center space-y-4 fade-in-up">
        <h1
          className="text-5xl font-extrabold mb-0"
          style={{ fontFamily: "Open Sans, sans-serif", color: "white" }}
        >
          Don't Overpay for Limited Use -{" "}
          <span style={{ color: "#FFD700" }}>Save Now!</span>
        </h1>
        <p
          className="text-xl text-gray-300"
          style={{ fontFamily: "Open Sans, sans-serif" }}
        >
          Unlock{" "}
          <span
            style={{
              fontWeight: "bold",
              color: "rgb(255 255 255)",
              background: "rgb(163 134 58)",
              padding: "2px 12px",
              fontSize: "25px",
              borderRadius: "19px",
            }}
          >
            ChatGPT’s
          </span>{" "}
          Full Power at a Discount - Boost Your Productivity Now!
        </p>
        <div className="w-full lg:w-2/3">
          <div className="bg-gray-700 bg-opacity-50 rounded-lg shadow-lg p-1 lg:p-2 fade-in-up">
            <img
              src="/images/product_gif.gif"
              alt="App Screenshot"
              className="w-full h-auto rounded-lg"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
        <Link
          to="/register"
          className="inline-block bg-yellow-400 text-gray-900 py-4 px-8 rounded-full font-bold text-lg shadow-md hover:bg-yellow-500"
          onClick={handleUnlockAccessClick}
          style={{ width: "100%", maxWidth: "640px", textAlign: "center" }}
        >
          <div className="flex items-center justify-center space-x-2">
            <FaUnlock />
            <span>Unlock Access</span>
          </div>
        </Link>
        <div
          className="text-sm text-gray-200"
          style={{
            fontFamily: "Open Sans, sans-serif",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "0 1rem",
          }}
        >
          <span className="hidden sm:inline-flex items-center">
            Join the First 100 Subscribers and Get a 7-Day Free Trial -&nbsp;
            {loading ? (
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginLeft: "8px" }}
              />
            ) : (
              <span>Only 21 Spots Left!</span>
            )}
          </span>
          <span className="sm:hidden">
            Join the First 100 Subscribers and Get a 7-Day Free Trial -
            <br />
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <span>Only 21 Spots Left!</span>
            )}
          </span>
        </div>
      </main>
      <Features />
      <Footer />
    </div>
  );
};

export default Home;
