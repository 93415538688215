import React, { useContext } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { LinearProgress, Box, Typography } from "@mui/material";
import ChatContext from "../ChatContext";

const TokenUsage = () => {
  const { tokenLimit, tokensUsed } = useContext(ChatContext);
  const percentageUsed = ((tokensUsed / tokenLimit) * 100).toFixed(2);

  return (
    <>
      <div className="hidden lg:block fixed top-4 right-4 bg-white p-4 rounded-lg shadow-md w-28 h-32 flex flex-col items-center justify-center">
        <div className="text-xs mb-2">Token Usage</div>
        <CircularProgressbar
          value={percentageUsed}
          text={``}
          styles={buildStyles({
            textSize: "8px",
            pathColor: `rgba(0, 123, 255, 1)`, // Darker blue shade
            textColor: "#333",
            trailColor: "#cfe2ff",
          })}
        />
      </div>

      <div className="block lg:hidden fixed top-4 right-4 bg-white p-2 rounded-lg shadow-md w-48 h-12 flex flex-col items-center justify-center">
        <Typography variant="caption" display="block" gutterBottom>
          Token Usage
        </Typography>
        <Box display="flex" alignItems="center" width="100%">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={percentageUsed}
              style={{ height: 10, borderRadius: 5 }}
            />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default TokenUsage;
