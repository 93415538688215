import React, { useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie"; // Import the js-cookie library
import { FaRupeeSign } from "react-icons/fa";
import ChatContext from "../ChatContext";

const PaymentButton = ({ amount, planType, tokenLimit, onClose }) => {
  const { setTokenLimit, setTokensUsed } = useContext(ChatContext);

  const handleSubscription = () => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Replace with your Razorpay key ID
      amount: amount * 100, // Amount is in paise, so convert it to rupees
      currency: "INR",
      name: "Primeaiaccess.com",
      description: `${planType} Subscription`,
      image: "/images/logo.jpg", // Replace with your company logo
      handler: async function (response) {
        try {
          const paymentInfo = {
            payment_id: response.razorpay_payment_id,
            amount: amount,
            currency: "INR",
            payment_date: new Date().toISOString(),
            status: "Completed",
          };

          const subscription = {
            plan_type: planType,
            start_date: new Date().toISOString(),
            end_date: new Date(
              new Date().setMonth(new Date().getMonth() + 1)
            ).toISOString(),
            is_active: true,
          };

          const username = Cookies.get("username");

          const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/confirm-payment`,
            {
              username: username,
              subscription: subscription,
              payment_info: paymentInfo,
              gpt_token_limit: tokenLimit,
            },
            { withCredentials: true }
          );

          setTokenLimit(data.token_limit);
          setTokensUsed(data.tokens_used);

          onClose(); // Close the dialog on successful payment and API call
        } catch (error) {
          console.error("Error confirming payment:", error);
        }
      },
      prefill: {
        email: Cookies.get("username"),
      },
      notes: {},
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <button
      className="mt-4 w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition"
      onClick={handleSubscription}
    >
      Buy Now
    </button>
  );
};

export default PaymentButton;
