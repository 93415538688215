import React from "react";

const AboutUs = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <p>
        Welcome to Chandra Web Solutions, your trusted partner for cutting-edge
        AI solutions. As a premier reseller of ChatGPT subscriptions, we are
        dedicated to providing our customers with the best AI tools to enhance
        their productivity, creativity, and business operations.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Our Mission</h2>
      <p>
        At Chandra Software Services, our mission is to democratize access to
        advanced AI technologies. We believe that AI has the potential to
        transform the way we live and work, and we strive to make these powerful
        tools accessible to everyone, from individuals to large enterprises.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">What We Offer</h2>
      <p>
        We offer a range of ChatGPT subscription plans designed to meet the
        diverse needs of our clients. Whether you are looking for a personal
        assistant to boost your productivity or a robust AI solution to drive
        your business forward, we have the right plan for you.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Why Choose Us?</h2>
      <ul className="list-disc ml-6">
        <li>
          <strong>Expertise:</strong> Our team comprises experienced
          professionals with a deep understanding of AI technologies and their
          applications.
        </li>
        <li>
          <strong>Customer Support:</strong> We are committed to providing
          exceptional customer service and support. Our team is always ready to
          assist you with any questions or issues you may have.
        </li>
        <li>
          <strong>Secure Payments:</strong> We ensure secure and seamless
          payment processing through trusted third-party gateways, giving you
          peace of mind when you subscribe to our services.
        </li>
        <li>
          <strong>Tailored Solutions:</strong> We offer customizable
          subscription plans to cater to your specific needs and budget.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-2">Our Values</h2>
      <p>
        Integrity, innovation, and customer satisfaction are at the core of
        everything we do. We are committed to maintaining the highest standards
        of business ethics and delivering value to our customers.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Contact Us</h2>
      <p>
        We would love to hear from you! If you have any questions, suggestions,
        or feedback, please reach out to us at{" "}
        <a href="mailto:support@primeaiaccess.com" className="text-blue-500">
          support@primeaiaccess.com
        </a>
        .
      </p>

      <p>
        Thank you for choosing Chandra Web Solutions. We look forward to serving
        you and helping you harness the power of AI.
      </p>
    </div>
  );
};

export default AboutUs;
